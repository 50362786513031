<template>
  <div class="entry-div">
    <b-card class="entry-card">
      <!-- <b-row class="justify-content-around timer-zone"> -->

      <b-row class="justify-content-around entry-row">
        <p>Egzersiz Kodu : {{ (exerciseCode = 1234566) }}</p></b-row>
      <b-row class="justify-content-around entry-row">
        <b-button
          v-ripple.400="'rgba(0, 255, 200, 0.15)'"
          class="entry-button"
          :variant="this.timerState ? 'primary' : 'secondary'"
          @click="timerClick()"
        >
          <feather-icon
            :icon="this.timerState ? 'PauseIcon' : 'PlayIcon'"
            size="1.5x"
            style="height: 100%; width: 25%; margin-left: 5px"
          />
          <!-- <feather-icon
              :class="this.timerState ? 'timer-active' : 'timer-paused'"
              icon="WatchIcon"
              size="1.5x"
              style="height: 100%; width: 50%"
            /> -->
          <p style="width: 75%">
            {{ this.minute < 10 ? "0" + this.minute : this.minute }} :
            {{ this.second < 10 ? "0" + this.second : this.second }}
          </p>
        </b-button>
        <b-button
          v-ripple.400="'rgba(200,80,80,0.25)'"
          class="entry-button"
          variant="danger"
          @click="mistakeClick()"
        >
          <feather-icon
            icon="XCircleIcon"
            size="1.5x"
            style="height: 100%; width: 25%; margin-left: 5px"
          />
          <p style="width: 75%">
            {{ this.mistake }}
          </p>
        </b-button>
      </b-row>
      <b-row
        v-if="this.showResult"
        class="justify-content-around entry-row"
      >
        <p class="grade-p">
          Süre Sınıfı:
          {{ this.timeGrade }}
        </p>
        <p class="grade-p">
          Hata Sınıfı:
          {{ this.mistakeGrade }}
        </p>
      </b-row>
      <!-- </b-row> -->
      <b-row
        v-if="this.showResult"
        class="justify-content-around submit-button"
      >
        <b-button
          v-ripple.400="'rgba(60,240,120,0.25)'"
          class="entry-button"
          variant="secondary"
          @click="resetExercise()"
        >
          <feather-icon
            icon="RefreshCwIcon"
            size="1.5x"
          />
        </b-button>
        <b-button
          v-ripple.400="'rgba(60,120,240,0.25)'"
          class="entry-button"
          variant="primary"
          @click="sendExercise()"
        >
          <feather-icon
            icon="SendIcon"
            size="1.5x"
          />
        </b-button>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BButtonGroup, BButton, BCard, BRow, BCol,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButtonGroup,
    BButton,
    BCard,
    BRow,
    BCol,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  props: {
    exerciseCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      minute: 0,
      second: 0,
      timerState: false,
      timeGrade: 'A',
      mistake: 0,
      mistakeGrade: 'A',
      showResult: false,
      pauseCorrection: 0,
      intervalStartTime: 0,
    }
  },
  methods: {
    timerClick() {
      this.timerState = !this.timerState
      this.showResult = this.timeGrade != null && this.mistakeGrade != null && !this.timerState
      if (this.timerState) {
        this.timerStart()
      } else {
        this.timerStop()
      }
    },
    timerStart() {
      this.timerState = true
      this.intervalStartTime = Date.now()
      this.timer = setTimeout(() => {
        // call timer as a timeout to adjust first invoke time by pause correction without changing 1 second interval timer.
        this.intervalStartTime = Date.now()
        if (this.second == 59) {
          this.minute++
          this.second = 0
          this.timeGrade = this.checkGrade(this.minute)
        } else {
          this.second++
        }

        this.timer = setInterval(() => {
          // when timeout is called, timeout becomes an interval instead.
          this.intervalStartTime = Date.now()
          if (this.second == 59) {
            this.minute++
            this.second = 0
            this.timeGrade = this.checkGrade(this.minute)
          } else {
            this.second++
          }
        }, 1000)
      }, 1000 - this.pauseCorrection)
    },
    timerStop() {
      this.timerState = false
      this.pauseCorrection = Date.now() - this.intervalStartTime
      clearTimeout(this.timer)
      clearInterval(this.timer)
    },
    checkGrade(min) {
      if (min < 2) {
        return 'B'
      }
      if (min < 3) {
        return 'C'
      }
      if (min < 4) {
        return 'D'
      }
      if (min < 5) {
        return 'E'
      }
      return 'F'
    },
    mistakeClick() {
      if (!this.timerState) return
      this.mistake++
      this.mistakeGrade = this.checkGrade(this.mistake)
    },
    sendExercise() {
      if (this.timeGrade == null || this.mistakeGrade == null || this.timerState) return
      console.log('send exercise', this.timeGrade, this.mistakeGrade)
      // this.$store.dispatch("sendExercise", {
      //   exerciseCode: this.exerciseCode,
      //   timeGrade: this.timeGrade,
      //   mistakeGrade: this.mistakeGrade,
      // });
    },
    resetExercise() {
      this.timerStop()
      this.mistake = 0
      this.mistakeGrade = 'A'
      this.timeGrade = 'A'
      this.minute = 0
      this.second = 0
      this.pauseCorrection = 0
      this.showResult = false
    },
  },
}
</script>
<style scoped>
.entry-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
p {
  margin: 0;
  font-size: large;
  font-weight: bold;
}
svg {
  height: 100%;
  width: 25%;
}
.entry-card {
  width: 100%;
  max-width: 400px;
}
.entry-button {
  max-width: 150px;
  font-size: 1.5rem;
  width: 48%;
  height: 50px;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  padding: 0;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.grade-p {
  min-width: 129px;
}
.entry-row {
  width: 100%;
  margin: 5px;
}
.submit-button {
  width: 100%;
  margin: 5px;
  margin-top: 25px;
}
/* .timer-active {
  background-color: red;
  padding: 5px;
  border-radius: 10px;
}
.timer-paused {
  background-color: green;
  padding: 5px;
  border-radius: 10px;
} */
</style>
